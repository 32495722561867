import { IDataTableViewOptions } from '@x/common/data';

export const WALLET_TABLE_VIEW_OPTIONS: IDataTableViewOptions = {
  id: 'wallet_index',
  columns: [
    { id: 'id', title: 'ID', sortable: true },
    { id: 'user', title: 'Customer' },
    { id: 'currency', title: 'Currency' },
    { id: 'balance', title: 'Total', sortable: true },
    { id: 'timestamps', title: 'Timestamps', sortable: true, sortId: 'updatedAt' },
  ],
  sorts: [],
  defaultColumns: ['id', 'user', 'balance', 'timestamps'],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200, 500],
  sort: {
    column: 'updatedAt',
    order: 'desc',
  },
};
